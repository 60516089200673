import { differenceBy, differenceWith, filter, first, flatMap, groupBy, isEmpty, isEqual, isNil, map, mapValues, omit, pick, reduce } from 'lodash'
import {
	apolloClient,
	GET_DISCIPLINES_SPECIALTIES_QUERY,
	GET_EMERGENCY_CONTACTS_QUERY,
	GET_PROFILE_BASIC_INFO_QUERY,
	GET_PROFILE_PHONE_VERIFIED_QUERY,
	GET_WORKER_RESUMES_QUERY,
	SEND_OTP_CODE_MUTATION,
	UPDATE_DISCIPLINES_SPECIALTIES_MUTATION,
	UPDATE_EMERGENCY_CONTACTS_MUTATION,
	UPDATE_WORKER_DISPLINE_SPECIALTY_MUTATION,
	UPDATE_WORKER_INFO_MUTATION,
	UPDATE_WORKER_RESUME_MUTATION,
	VERIFY_OTP_CODE_MUTATION,
} from '~/common/apollo'
import { FIELD_MODE, INFO_PROFILE_TRACKING_EVENT, PROFILE_STEP } from '~/common/constants'
import { captureException, mutateWithTrimmedVariables } from '~/common/helpers'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores'
import { careStore } from '~/companies/care/care.store'
import i18next from 'i18next'
import { logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { processDisciplineSpecialtyChanges } from '~/common/helpers/discipline-specialty.helper'

const VALID_ADDRESS_FIELDS = ['id', 'street', 'city', 'zipcode', 'country', 'state', 'default', 'latitude', 'longitude']

@store()
class CareProfileStore {
	@observable prevActiveTabIndex = -1
	@observable worker = {}
	@observable contacts = []
	@observable fieldMode = FIELD_MODE.view
	@observable workerNewEmail
	@observable submitPersonalInfo = {}
	@observable trackingParams

	@observable activeTabIndex = 0
	@observable groupedSpecialtiesPure = {}
	@observable verifyPhoneState = false
	@observable addPhoneState = false
	@observable allowShowPopup = false
	@observable preferState = []
	@observable resumes = []

	@observable groupedSpecialties = {}
	@observable primarySpecialty = {}

	@action
	setTrackingParams = async (params) => {
		this.trackingParams = params
	}

	@action
	setAllowShowPopup = async (state) => {
		this.allowShowPopup = state
	}

	@action
	setWorkerNewEmail = (value) => {
		this.workerNewEmail = value
	}

	@action
	setVerifyPhoneState = async (state) => {
		this.verifyPhoneState = state
	}

	@action
	setAddPhoneState = async (state) => {
		this.addPhoneState = state
	}

	@computed
	get hasAssignedRecruiterOrManager() {
		return this.worker?.hasAssignedRecruiterOrManager
	}

	@computed
	get workerPhone() {
		return this.worker?.phone
	}

	@computed
	get workerEmail() {
		return this.worker?.email
	}

	@computed
	get phoneNotExisted() {
		return isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
	}

	@computed
	get groupedSpecialtiesExcludePrimary() {
		return omit(this.groupedSpecialties, [this.primarySpecialty.profession])
	}

	@computed
	get groupedSpecialtiesPrimary() {
		return Object.keys(this.groupedSpecialties).reduce((accumulative, currentValue) => {
			accumulative[currentValue] = this.groupedSpecialties[currentValue].filter((item) => item.id !== this.primarySpecialty.specialty)

			return accumulative
		}, {})
	}

	@computed
	get workingPreferredLocations() {
		return this.worker.workingPreferredLocations?.find((item) => item.isPrimary)
	}

	@computed
	get otherPreferredLocations() {
		return this.worker.workingPreferredLocations?.filter((item) => !item.isPrimary) ?? []
	}

	@computed
	get primaryAddress() {
		return this.worker.addresses?.find((item) => item.default)
	}

	@computed
	get temporaryAddress() {
		return this.worker.addresses?.find((item) => !item.default)
	}

	@computed
	get infoInitialValues() {
		this.preferState = this.worker?.workingPreferredLocations

		return {
			...this.worker,
			workerAddress: this.primaryAddress && pick(this.primaryAddress, VALID_ADDRESS_FIELDS),
			temporaryAddress: this.temporaryAddress && pick(this.temporaryAddress, VALID_ADDRESS_FIELDS),
			preferredLocation: this.workingPreferredLocations?.state,
			otherPreferredLocations: this.otherPreferredLocations,
		}
	}

	@computed
	get professionInitialValues() {
		return {
			specialty: [],
			discipline: null,
		}
	}

	@computed
	get contactInitialValues() {
		return [...(this.contacts ?? [])]
	}

	@action
	addNewContact = () => {
		const nullContact = {
			firstName: null,
			lastName: null,
			phone: null,
			relationship: null,
		}
		this.contacts = [...this.contacts, nullContact]
	}

	@computed
	get resumeInitialValues() {
		const { resumes } = this.worker || {}

		const resumeFull = first(
			resumes?.map((item) => {
				return {
					...item.file,
					...item,
				}
			})
		)
		return {
			...pick(this.worker, ['id']),
			resumes: resumeFull ? [resumeFull] : [],
		}
	}

	@computed
	get phoneVerified() {
		return !!this.worker?.phoneVerifiedAt
	}

	@computed
	get notiMessage() {
		const step = {
			[PROFILE_STEP.basic_info]: 'emergency contact',
			[PROFILE_STEP.education]: 'education',
			[PROFILE_STEP.work_experience]: 'work experience',
			[PROFILE_STEP.reference]: 'reference',
		}
		return {
			add: {
				message: 'ADD_MESSAGE',
				step,
			},
			delete: {
				message: 'REMOVE_MESSAGE',
				step,
			},
			edit: 'EDIT_MESSAGE',
		}
	}

	@computed
	get tabLabel() {
		return ['PERSONAL_INFORMATION', 'EDUCATION_HISTORY', 'WORK_EXPERIENCE', 'REFERENCES', 'SKILLS_CHECKLIST', 'TAX_CREDIT', 'TERM_SIGNED']
	}

	@action
	showDialogForOneItem = (message, eventName, onOk) => {
		void notifyStore.success('$MESSAGES.MINIMUM_ITEM_REMOVE', {
			showDialog: true,
			eventName: eventName,
			title: i18next.t(message),
			okText: i18next.t('I_GOT_IT'),
			...(onOk
				? {
						onOk: (key) => {
							onOk()
							notifyStore.closeDialog(key)
						},
				  }
				: {}),
		})

		return
	}

	@action
	addProfessions = async (profession, specialties) => {
		if (specialties.length)
			await Promise.all(
				specialties.map((specialty, index) => {
					const submitData = {
						disciplineId: profession,
						specialtyId: specialty,
						isPrimary: index === 0 && !Object.keys(this.groupedSpecialties).length,
					}

					return this.updateDisciplinesSpecialties(submitData)
				})
			).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
		else {
			const submitData = {
				disciplineId: profession,
				specialtyId: null,
				isPrimary: !Object.keys(this.groupedSpecialties).length,
			}

			await this.updateDisciplinesSpecialties(submitData).then(() => void notifyStore.success('$MESSAGES.SUCCESSFUL'))
		}

		void this.fetchDisciplinesSpecialties()
	}

	@action
	removeProfession = async (profession) => {
		await Promise.all(
			this.groupedSpecialties[profession]?.map((specialty) => {
				const submitData = {
					id: specialty.parentId,
					_destroy: true,
				}

				return this.updateDisciplinesSpecialties(submitData)
			})
		)
		void notifyStore.success('$MESSAGES.SUCCESSFUL')

		void this.fetchDisciplinesSpecialties()
	}

	@action
	removeSpecialty = async (profession, specialty) => {
		const submitData = {
			id: specialty.parentId,
			_destroy: true,
		}

		await this.updateDisciplinesSpecialties(submitData)
		void notifyStore.success('$MESSAGES.SUCCESSFUL')

		void this.fetchDisciplinesSpecialties()
	}

	@action
	setPrimaryProfession = async (profession) => {
		const currentPrimaryData = {
			id: this.primarySpecialty.parentId,
			isPrimary: false,
		}
		const submitData = {
			id: this.groupedSpecialties[profession]?.[0]?.parentId,
			isPrimary: true,
		}

		await this.updateDisciplinesSpecialties(currentPrimaryData)
		await this.updateDisciplinesSpecialties(submitData)
		void notifyStore.success('$MESSAGES.SUCCESSFUL')

		void this.fetchDisciplinesSpecialties()
	}

	@action
	setPrimarySpecialty = async (profession, specialty) => {
		const currentPrimaryData = {
			id: this.primarySpecialty.parentId,
			isPrimary: false,
		}
		const submitData = {
			id: specialty.parentId,
			isPrimary: true,
		}

		await this.updateDisciplinesSpecialties(currentPrimaryData)
		await this.updateDisciplinesSpecialties(submitData)
		void notifyStore.success('$MESSAGES.SUCCESSFUL')

		void this.fetchDisciplinesSpecialties()
	}

	@action
	fetchWorkerBasicInfo = async () => {
		const { data } = await apolloClient.query({
			query: GET_PROFILE_BASIC_INFO_QUERY,
		})
		this.worker = data?.worker
		this.workerNewEmail = this.worker?.email

		// this.verifyPhoneState = isEmpty(this.worker?.phoneVerifiedAt)
		// this.addPhoneState = isEmpty(this.worker?.phone) || this.worker?.phone === '+1'
	}

	@action
	fetchWorkerResumes = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_RESUMES_QUERY,
		})
		this.resumes = data?.worker?.resumes ?? []
	}

	@action
	fetchWorkerPhoneVerified = async (setValues) => {
		const { data } = await apolloClient.query({
			query: GET_PROFILE_PHONE_VERIFIED_QUERY,
		})
		this.worker.phoneVerifiedAt = data?.worker?.phoneVerifiedAt
		this.worker.phone = data?.worker?.phone

		if (typeof setValues === 'function') {
			setValues(
				omit({
					...this.submitPersonalInfo,
					phone: data?.worker?.phone,
					phoneVerifiedAt: data?.worker?.phoneVerifiedAt,
				})
			)
		}

		if (this.worker.email !== this.workerNewEmail) {
			this.worker.email = this.workerNewEmail
		}
	}

	@action
	fetchDisciplinesSpecialties = async () => {
		const { data } = await apolloClient.query({
			query: GET_DISCIPLINES_SPECIALTIES_QUERY,
		})

		const results = data?.worker?.workerDisciplineSpecialties ?? []

		this.groupedSpecialties = mapValues(
			groupBy(results, (specialty) => specialty?.discipline?.id),
			(values) => values.map((value) => ({ ...value.specialty, parentId: value.id, isPrimary: value.isPrimary }))
		)
		this.groupedSpecialtiesPure = mapValues(
			groupBy(results, (specialty) => specialty?.discipline?.id),
			(values) => values.map((value) => ({ ...value.specialty, parentId: value.id, isPrimary: value.isPrimary }))
		)
		const matchedResult = results?.find((specialty) => specialty.isPrimary)
		if (matchedResult) {
			this.primarySpecialty = {
				profession: matchedResult?.discipline?.id,
				professionName: matchedResult?.discipline?.disciplineName,
				specialty: matchedResult?.specialty?.id,
				specialtyName: matchedResult?.specialty?.name,
				parentId: matchedResult?.id,
				hasSkillChecklist: matchedResult.specialty?.hasSkillChecklist,
			}
		}
	}

	@action
	updateDisciplinesSpecialties = async (values) => {
		try {
			await apolloClient.mutate({
				mutation: UPDATE_DISCIPLINES_SPECIALTIES_MUTATION,
				variables: {
					workerDisciplineSpecialties: { ...values },
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchEmergencyContacts = async () => {
		const { data } = await apolloClient.query({
			query: GET_EMERGENCY_CONTACTS_QUERY,
		})
		this.contacts = data?.worker?.workerEmergencyContacts ?? []
	}

	@action
	updateEmergencyContacts = async (values) => {
		try {
			await mutateWithTrimmedVariables({
				mutation: UPDATE_EMERGENCY_CONTACTS_MUTATION,
				variables: {
					workerEmergencyContacts: { ...values },
				},
			})

			await this.fetchEmergencyContacts()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	handleSubmitInfo = async (values, resetForm) => {
		try {
			const updateData = { ...values }

			updateData.addresses = []

			if (updateData.workerAddress?.city || updateData.workerAddress?.street || updateData.workerAddress?.state) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...pick(updateData.workerAddress, VALID_ADDRESS_FIELDS),
						default: true,
						zipcode: updateData.workerAddress.zipcode?.toString() ?? '',
					},
				]
			}

			if (updateData.temporaryAddress?.city || updateData.temporaryAddress?.street || updateData.temporaryAddress?.state) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...pick(updateData.temporaryAddress, VALID_ADDRESS_FIELDS),
						default: false,
						zipcode: updateData.temporaryAddress.zipcode?.toString() ?? '',
					},
				]
			}

			if (
				this.infoInitialValues.temporaryAddress?.id &&
				!updateData.temporaryAddress?.city &&
				!updateData.temporaryAddress?.street &&
				!updateData.temporaryAddress?.state
			) {
				updateData.addresses = [
					...(updateData.addresses ?? []),
					{
						...this.infoInitialValues.temporaryAddress,
						_destroy: true,
					},
				]
			}

			if (values.preferredLocation !== this.workingPreferredLocations?.state) {
				if (this.workingPreferredLocations?.id) {
					updateData.workingPreferredLocations = [
						{
							...this.workingPreferredLocations,
							_destroy: true,
						},
					]
				}
				updateData.workingPreferredLocations = [
					...(updateData.workingPreferredLocations ?? []),
					{
						state: values.preferredLocation,
						isPrimary: true,
					},
				]
			}

			if (!isEqual(values.otherPreferredLocations, this.otherPreferredLocations)) {
				const mappedOtherPreferredLocations =
					values.otherPreferredLocations?.map((item) => ({
						state: item.state,
						city: item.city,
						isPrimary: false,
					})) ?? []

				const newItems = differenceWith(
					mappedOtherPreferredLocations,
					this.otherPreferredLocations,
					(item1, item2) => item1.state === item2.state && item1.city === item2.city
				)

				const removedItems = differenceWith(
					this.otherPreferredLocations,
					mappedOtherPreferredLocations,
					(item1, item2) => item1.state === item2.state && item1.city === item2.city
				).map((item) => ({ id: item.id, _destroy: true }))

				updateData.workingPreferredLocations = [...(updateData.workingPreferredLocations ?? []).filter((item) => item.isPrimary), ...newItems, ...removedItems]
			}

			await mutateWithTrimmedVariables({
				mutation: UPDATE_WORKER_INFO_MUTATION,
				variables: omit(updateData, ['workerAddress', 'temporaryAddress', 'preferredLocation', 'otherPreferredLocations', 'phone', 'id']),
			})
			logDefaultActionEvent(INFO_PROFILE_TRACKING_EVENT.PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_SUCCESS, this.trackingParams)
			await this.fetchWorkerBasicInfo()
			void careStore.fetchWorkerBasicInfo()
			void notifyStore.success('EDIT_MESSAGE')
		} catch (error) {
			captureException('Care Profile', error)
			logDefaultActionEvent(INFO_PROFILE_TRACKING_EVENT.PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_FAILED, this.trackingParams)
			const { graphQLErrors } = error
			if (graphQLErrors?.[0]?.message) {
				notifyStore.error(graphQLErrors?.[0]?.message)
			} else {
				notifyStore.error(error?.message)
			}
		} finally {
			if (typeof resetForm === 'function') {
				resetForm()
			}
		}
	}

	@action
	handleSubmitInfoApply = async (values, resetForm) => {
		try {
			const updateData = { ...values }
			await mutateWithTrimmedVariables({
				mutation: UPDATE_WORKER_INFO_MUTATION,
				variables: omit(updateData, ['workerAddress', 'temporaryAddress', 'preferredLocation', 'otherPreferredLocations', 'phone', 'id']),
			})

			await this.fetchWorkerBasicInfo()
			void careStore.fetchWorkerBasicInfo()
			void notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			captureException('Care Profile', error)
			const { graphQLErrors } = error
			if (graphQLErrors?.[0]?.message) {
				notifyStore.error(graphQLErrors?.[0]?.message)
			} else {
				notifyStore.error(error?.message)
			}
		} finally {
			if (typeof resetForm === 'function') {
				resetForm()
			}
		}
	}

	@action
	syncPersonalInfo = (newInfo) => {
		this.submitPersonalInfo = { ...newInfo }
	}

	@action
	handleSubmitResume = async (values, { setErrors }) => {
		try {
			const signedBlobIds = filter(map(values.resumes, (resume) => !resume._destroy && resume.signedBlobId))

			const { data } = await apolloClient.mutate({
				mutation: UPDATE_WORKER_RESUME_MUTATION,
				variables: { resumesSignedBlobIds: signedBlobIds },
			})

			this.worker = { ...this.worker, resumes: data?.createOrUpdateWorkerResumes.resumes }
		} catch (error) {
			captureException('Care Profile - Resume Tab - Upload Resume', error)
		}
	}

	@action
	handleSendOTP = async (variables) => {
		try {
			await apolloClient.mutate({ mutation: SEND_OTP_CODE_MUTATION, variables })
			notifyStore.success('$MESSAGES.SEND_OTP')
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleSubmitOTP = async (variables) => {
		try {
			await apolloClient.mutate({ mutation: VERIFY_OTP_CODE_MUTATION, variables })
			notifyStore.success('$MESSAGES.VERIFY_PHONE')

			this.setVerifyPhoneState(false)
			this.setAllowShowPopup(false)

			await this.fetchWorkerDetail()
			await careStore.fetchWorkerDetail()
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleSubmitProfession = async (values, resetForm) => {
		try {
			const updateData = {}
			const oldProfessionsSpecialties = this.groupedSpecialtiesPure
			const newProfessionsSpecialties = this.submitPersonalInfo.professionInfo
			if (!isNil(newProfessionsSpecialties)) {
				const formattedInitialSpecialties = (updateData.workerDisciplineSpecialties ?? []).map((item) => ({
					id: item.id,
					disciplineId: item.discipline?.id,
					specialtyId: item.specialty?.id,
					isPrimary: item.isPrimary,
				}))

				const finalSpecialties = processDisciplineSpecialtyChanges({
					oldProfessionsSpecialties,
					newProfessionsSpecialties,
					formattedInitialSpecialties,
				})

				if (!isNil(newProfessionsSpecialties)) {
					updateData.workerDisciplineSpecialties = finalSpecialties
				}

				await apolloClient.mutate({
					mutation: UPDATE_WORKER_DISPLINE_SPECIALTY_MUTATION,
					variables: omit(updateData, ['workerAddress', 'temporaryAddress', 'preferredLocation', 'otherPreferredLocations', 'phone', 'id']),
				})
				logDefaultActionEvent(INFO_PROFILE_TRACKING_EVENT.PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_SUCCESS, this.trackingParams)
				await this.fetchWorkerBasicInfo()
				await this.fetchDisciplinesSpecialties()
				void careStore.fetchWorkerBasicInfo()
				void notifyStore.success('EDIT_MESSAGE')
			}
		} catch (error) {
			captureException('Care Profile', error)
			logDefaultActionEvent(INFO_PROFILE_TRACKING_EVENT.PROFILE_MANAGEMENT_PERSONAL_INFO_SUBMIT_FAILED, this.trackingParams)
			const { graphQLErrors } = error
			if (graphQLErrors?.[0]?.message) {
				notifyStore.error(graphQLErrors?.[0]?.message)
			} else {
				notifyStore.error(error?.message)
			}
			resetForm()
		} finally {
			if (typeof resetForm === 'function') {
				resetForm()
			}
		}
	}

	@action
	handleUpdatePhone = async (variables) => {
		try {
			await apolloClient.mutate({
				mutation: UPDATE_WORKER_INFO_MUTATION,
				variables: { ...variables, id: this.worker.id },
			})
			// this.worker = data.updateIntegrationWorker

			notifyStore.success('$MESSAGES.UPDATE_PHONE')
		} catch (error) {
			notifyStore.error(error?.message)
		}
		this.setAddPhoneState(false)
	}
}

export const careProfileStore = new CareProfileStore()
