import { differenceBy, flatMap, isEmpty, isNil } from 'lodash'

/**
 * Process discipline and specialty changes
 * @param {Object} params
 * @param {Object} params.oldProfessionsSpecialties - Current disciplines and specialties
 * @param {Object} params.newProfessionsSpecialties - New disciplines and specialties to update
 * @param {Array} params.formattedInitialSpecialties - Initial specialties if any
 * @returns {Array} Processed specialties with changes
 */
export const processDisciplineSpecialtyChanges = ({ oldProfessionsSpecialties, newProfessionsSpecialties, formattedInitialSpecialties = [] }) => {
	if (isNil(newProfessionsSpecialties)) {
		return []
	}

	const mappingOldSpecialty = flatMap(Object.entries(oldProfessionsSpecialties || {}), ([key, value]) => {
		return value.length > 0
			? value.map((item) => ({ ...item, key }))
			: [
					{
						key,
						id: null,
						isPrimary: false,
						parentId: null,
					},
			  ]
	})

	const mappingNewSpecialty = Object.entries(newProfessionsSpecialties || {}).flatMap(([key, value]) => {
		return value.length > 0
			? value.map((item) => ({ ...item, key }))
			: [
					{
						key,
						id: null,
						isPrimary: false,
						parentId: null,
					},
			  ]
	})

	let allSpecialtyChanges = [...formattedInitialSpecialties]

	// First, handle disciplines that need to be removed (not in newProfessionsSpecialties)
	const removedDisciplines = Object.keys(oldProfessionsSpecialties || {}).filter(
		(disciplineId) => !Object.keys(newProfessionsSpecialties || {}).includes(disciplineId)
	)

	// Add all specialties from removed disciplines to be deleted
	removedDisciplines.forEach((disciplineId) => {
		const specialties = oldProfessionsSpecialties[disciplineId] || []
		specialties.forEach((specialty) => {
			allSpecialtyChanges.push({
				id: specialty.parentId,
				disciplineId: disciplineId,
				specialtyId: specialty.id,
				isPrimary: false,
				_destroy: true,
			})
		})
	})

	const oldCheckSpecialty = mappingOldSpecialty?.find((item) => item.isPrimary === true)
	const newCheckSpecialty = mappingNewSpecialty?.find((item) => item.isPrimary === true)
	const checkIsChangePrimarySpecialtyIdOld =
		oldCheckSpecialty?.hasOwnProperty('id') && !isEmpty(oldCheckSpecialty) ? oldCheckSpecialty?.id : oldCheckSpecialty?.parentId
	const checkIsChangePrimarySpecialtyIdNew =
		newCheckSpecialty?.hasOwnProperty('id') && !isEmpty(newCheckSpecialty) ? newCheckSpecialty?.id : newCheckSpecialty?.parentId
	const isChangePrimarySpecialty =
		checkIsChangePrimarySpecialtyIdOld !== checkIsChangePrimarySpecialtyIdNew && (!!checkIsChangePrimarySpecialtyIdOld || !!checkIsChangePrimarySpecialtyIdNew)

	if (isChangePrimarySpecialty) {
		const oldPrimarySpecialty = mappingOldSpecialty?.find((item) => item.isPrimary === true)
		const newPrimarySpecialty = mappingNewSpecialty?.find((item) => item.isPrimary === true)

		if (oldPrimarySpecialty) {
			allSpecialtyChanges.push({
				id: oldPrimarySpecialty?.parentId,
				disciplineId: oldPrimarySpecialty?.key,
				specialtyId: oldPrimarySpecialty?.id,
				isPrimary: false,
			})
		}

		if (newPrimarySpecialty) {
			allSpecialtyChanges.push({
				id: newPrimarySpecialty?.parentId,
				disciplineId: newPrimarySpecialty?.key,
				specialtyId: newPrimarySpecialty?.id,
				isPrimary: true,
			})
		}
	}

	// Handle new items - ensure each has a value and not already in allSpecialtyChanges
	const newItems = differenceBy(mappingNewSpecialty, mappingOldSpecialty, (item) => item?.key)
		.filter((item) => !allSpecialtyChanges.some((existing) => existing.disciplineId === item.key && existing.specialtyId === item.id))
		.map((item) => ({
			disciplineId: item.key,
			specialtyId: item.id,
			isPrimary: item.isPrimary || false,
			id: item.parentId,
		}))

	// Handle removed items within existing disciplines
	const existingDisciplines = Object.keys(newProfessionsSpecialties || {})
	const removedItems = differenceBy(mappingOldSpecialty, mappingNewSpecialty, (item) => item?.key)
		.filter(
			(item) =>
				existingDisciplines.includes(item.key) &&
				!allSpecialtyChanges.some((existing) => existing.disciplineId === item.key && existing.specialtyId === item.id)
		)
		.map((item) => ({
			id: item.parentId,
			disciplineId: item.key,
			specialtyId: item.id,
			isPrimary: false,
			_destroy: true,
		}))

	allSpecialtyChanges = [...allSpecialtyChanges, ...newItems, ...removedItems]

	// Ensure no empty arrays in final result and each item has required values
	return allSpecialtyChanges
		.filter((item) => item.disciplineId)
		.map((item) => ({
			...item,
			specialtyId: item.specialtyId || null, // Ensure specialtyId is null if not present
			isPrimary: item.isPrimary || false, // Ensure isPrimary has a value
		}))
}
